import { Link } from "@StarberryUtils";
import * as React from "react"
// Images
// styles
import "./ContactCard.scss"
import { propTypes } from "react-bootstrap/esm/Image";
// markup
import noImage from "../../../images/no-image.jpg"
import {ImageModule} from "../../../modules/Image_Module";


const ContactCard = (props) => { 

  return (
    <div className="contact-card">
      <Link to={props.cardUrl}>
        <figure>
          <span className="overlay_bg"></span>
          {
            props.cardImg && (props.cardImg.url || props.cardImg.srcUrl) ? (
              <ImageModule ImageSrc={props.cardImg} imagename={props.imagename} article_id={props.article_id} imagetransforms={props.imagetransforms} ggfxNew={true} />
            ) : (
              <img src={noImage} alt="" />
            )
          }
          
          {props.cardTag && <span className="card-tag">{props.cardTag}</span> }
        </figure>
        
        <div className="info">
            <h6 className="card-title">{props.cardTitle}</h6>
            {props.date &&
            <span className="date">{props.date}</span>
            }
        </div>
      </Link>
    </div>
  )
}

export default ContactCard